import React from 'react';

const VerifiedFood: React.FC = () => {
    return (
        <div className="VerifiedFood">
            Verified
        </div>
    )
}

export default VerifiedFood;
