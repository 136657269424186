import React from 'react';

const UnUnVerifiedFood: React.FC = () => {
    return (
        <div className="UnVerifiedFood">
            UnVerified
        </div>
    )
}

export default UnUnVerifiedFood;
