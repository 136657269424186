import React from "react";


export const TrainerWriteSlide: React.FC = () => {

    return (
        <div className="FormContainer TrainerWriteSlide">
            
        </div>
    )
}
